
.li-icon {
    color: gold;
}

.text-uppercase {
    text-transform: uppercase;
}

.card-header h2:hover, .h2:hover, .h4:hover {
    background-color: rgba(0, 0, 0, 0.1);
}


.card {
    background-color: rgba(0, 0, 0, 0.7); /* Slightly darker transparent background */
    border: none;
    color: #fff; /* White text color */
    transition: background-color 0.3s ease;
    height: 100%;
  }
  
  .card:hover {
    background-color: rgba(0, 0, 0, 0.9); /* Darker background on hover */
  }

.card-header {
    background-color: transparent;
    border-bottom: none;
}



@media (min-width: 768px) {
   
}


.overlay {
    position: relative;
    z-index: 1;
  }
  
 .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency here */
    background-image: url('./images/young adults.jpg'); /* Path to your image */
    background-size: contain; /* Ensures the entire image is visible */
    background-repeat: repeat;
    background-position: center center;
    z-index: 0;
  }

  .title-color {
    color: white;
  }