.container {
    max-width: 1200px;
}

.navbar {
    margin-bottom: 20px;
}

.li-icon {
    color: purple;
}

.text-uppercase {
    text-transform: uppercase;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.card-header h2:hover, .h5:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.card-body {
    color: white;
}

.card {
    height: 100%;
}

.card-header {
    background-color: transparent;
    border-bottom: none;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card p {
    margin: 0;
}

@media (min-width: 768px) {
   
}
