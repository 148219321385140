
.text-navy {
    color: orange;
  }
  
  .text-burgundy {
    color: #800020; /* Burgundy color */
  }
  
  .fa-lg {
    font-size: 1.25em; /* Adjust size to match heading */
  }
  
  .navbar-fixed-top {
    top: 60px; /* Adjust this value based on the height of the primary fixed nav */
    z-index: 1030; /* Ensure it stays above other elements */
  }
  
  @media (min-width: 992px) {
    .navbar-fixed-top {
      top: 70px; /* Adjust this value based on the height of the primary fixed nav on larger screens */
    }
  }
  
  .overlay {
    position: relative;
    z-index: 1;
  }
  
 .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency here */
    background-image: url('./images/planning_committee_in_adventurers_uniform.jpg'); /* Path to your image */
    background-size: contain; /* Ensures the entire image is visible */
    background-repeat: repeat;
    background-position: center center;
    z-index: 0;
  }
  
  .card {
    background-color: rgba(0, 0, 0, 0.7); /* Slightly darker transparent background */
    border: none;
    color: #fff; /* White text color */
    transition: background-color 0.3s ease;
  }
  
  .card:hover {
    background-color: rgba(0, 0, 0, 0.9); /* Darker background on hover */
  }
  ol {
    list-style-type: none;
    padding-left: 1em; /* Adjust indentation if needed */
  }
  ul {
    list-style-type: none;
    padding-left: 1em; /* Adjust indentation if needed */
  }
  
  /* Optional: Style for list items */
  ul li {
    margin-bottom: 0.5em; /* Adjust spacing between list items */
  }